@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=SUSE:wght@100..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.cdnfonts.com/css/gt-america-trial');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap'); */

body {
    font-family: 'Poppins', sans-serif;
    scroll-behavior: smooth;
}

.splide__pagination__page {
    background: #474747 !important;
    opacity: 1;
    top: 5vh;
}

.buy-button {
    animation: shake 5s;
    animation-iteration-count: infinite;
}

@keyframes shake {
    0% {
        transform: translate(-50%, 1px) rotate(1deg);
    }

    10% {
        transform: translate(-50%, -1px) rotate(-1deg);
    }

    20% {
        transform: translate(-50%, 1px) rotate(1deg);
    }

    30% {
        transform: translate(-50%, -1px) rotate(-1deg);
    }

    40% {
        transform: translate(-50%, 1px) rotate(1deg);
    }

    50% {
        transform: translate(-50%, -1px) rotate(-1deg);
    }

    60% {
        transform: translate(-50%, 1px) rotate(1deg);
    }

    70% {
        transform: translate(-50%, -1px) rotate(-1deg);
    }

    80% {
        transform: translate(-50%, 1px) rotate(1deg);
    }

    90% {
        transform: translate(-50%, -1px) rotate(-1deg);
    }

    100% {
        transform: translate(-50%, 1px) rotate(1deg);
    }
}